<div class="container">
    <div class="row row-cols-1 row-cols-md-3">
        <div class="col mb-4" style="display: flex; flex-direction: column;">
            <div style="display: flex; margin-left: 50px; margin-bottom: 20px;">
                <img style=" margin-left: -50px auto 10px;" width="120" height="100"
                    src="assets/banners-home/logo_nota_premiada_01.png" alt="Descrição da Imagem">
            </div>
            <p style="margin: 0;"><b>Secretaria de Estado da Fazenda</b></p>
            <p style="margin-bottom: 0;">Rua Benjamin Constant, 946 - Centro</p>
            <p style="margin-bottom: 0;">Rio Branco - AC, 69900-062</p>
            <p style="margin-bottom: 0;">CNPJ: 04.034.484/0001-40</p>
            <p style="margin-bottom: 0;">UASG: 926063</p>
            <p style="margin-bottom: 0;"><b>ACOMPANHE NAS REDES</b></p>
            <a href="https://www.instagram.com/sefazacre/"
              style="cursor: pointer" target="_blank"> &#64;sefazacre
        </a>

        </div>
        <div class="col mb-4">
            <h5><b>O PROGRAMA</b></h5>
            <p routerLink = "/programa/conheca-programa" style = "cursor: pointer; margin-bottom: 0;">Conheça o Programa</p>
            <p routerLink = "/programa/educacao-fiscal" style = "cursor: pointer; margin-bottom: 0;">Educação Fiscal</p>
            <p style = "cursor: pointer" routerLink = "/programa/legislacao">Legislação</p>
            <h5> <b>PESSOA FÍSICA</b></h5>
            <p style="cursor: pointer;" routerLink="/pessoa/cadastro">Cadastro de Pessoa Física</p>
            <h5> <b>SORTEIOS</b></h5>
            <p style = "cursor: pointer; margin-bottom: 0;" routerLink="/sorteio/como-funciona">Como Funciona</p>
            <p style = "cursor: pointer; margin-bottom: 0"  routerLink="/sorteio/sorteios-realizados" >Sorteios Realizados</p>
            <p style="margin-bottom: 0; cursor: pointer;" routerLink="/sorteio/premiacao-instituicao">Premiação das Instituições</p>
            <p style = "cursor: pointer;" routerLink = "/sorteio/como-receber">Como Receber o Prêmio</p>
        </div>
        <div class="col mb-4">
            <h5><b>ENTIDADES SOCIAIS</b></h5>
            <p style="margin-bottom: 0; cursor: pointer;" routerLink="/entidade-social/entidades-cadastradas" >Entidades Cadastradas</p>
            <p style="margin-bottom: 0; cursor: pointer;" routerLink="/entidade-social/documentacao">Documentação Necessária</p>
            <p style=" cursor: pointer; margin-bottom: 0;" routerLink="/entidade-social/cadastro">Solicitar Cadastramento</p>
            <p style=" cursor: pointer;" routerLink="/entidade-social/acompanhar-solicitacao">Acompanhar Solicitação</p>
            <h5><b>AJUDA</b></h5>
            <p style="margin-bottom: 0; cursor: pointer;" routerLink="/ajuda/gestao-programa">Gestão do Programa</p>
            <p style="margin-bottom: 0; cursor: pointer;" routerLink="/ajuda/fale-conosco">Fale Conosco</p>
            <a style="text-decoration: none;" href="../../assets/manual_usuario/manual_usuario_externo.pdf"
            download="Manual Usuário (Pessoa Fisica e Entidade Social) - Nota Premiada Acreana.pdf">
              <p style="cursor: pointer; margin-bottom: 0;">Manual do usuário</p>
            </a>
            <p style="cursor: pointer; margin-bottom: 0;" routerLink="ajuda/material-divulgacao">Material de Divulgação</p>
            <p style="margin-bottom: 0; cursor: pointer;" routerLink="/ajuda/reativar-cadastro">Reativar Cadastro</p>
            <p style="margin-bottom: 0; cursor: pointer;" routerLink="/ajuda/reenviar-email">Reenviar Email</p>
        </div>
    </div>
</div>
